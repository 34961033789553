<template>
  <path id="Tracé_586" data-name="Tracé 586" d="M30.4,62.319a1.686,1.686,0,0,1,1.681-1.688,28.564,28.564,0,1,0-7.487-.968,1.685,1.685,0,0,1-.873,3.255A32.157,32.157,0,1,1,32.09,64h-.007A1.688,1.688,0,0,1,30.4,62.319ZM34.474,48.1a1.686,1.686,0,0,1-.223-2.371l9.988-12.04H16.177a1.686,1.686,0,1,1,0-3.371H44.239L34.25,18.274a1.684,1.684,0,1,1,2.594-2.148l12.279,14.8a0,0,0,0,0,0,0s0,.005.005.01a.752.752,0,0,1,.057.076c.014.017.025.031.039.049s0,.005,0,.009a.452.452,0,0,1,.045.078.319.319,0,0,1,.034.054.03.03,0,0,1,.008.014c.012.022.023.047.033.067s.021.045.031.07c0,0,0,.007.005.014a.49.49,0,0,1,.023.064.438.438,0,0,1,.025.076.117.117,0,0,0,.006.017c0,.016.007.031.012.047a.076.076,0,0,1,.005.027.4.4,0,0,1,.02.07s0,.01,0,.014,0,.029.007.044.006.043.01.062a.309.309,0,0,1,.005.045v.008a.31.31,0,0,1,0,.066c0,.029,0,.06,0,.093v0c0,.031,0,.061,0,.093a.289.289,0,0,1,0,.064v.01a.369.369,0,0,1-.005.044c0,.02-.005.041-.01.062s0,.03-.007.045,0,.007,0,.014a.413.413,0,0,1-.02.069.085.085,0,0,1-.005.028c0,.015-.008.031-.012.046s0,.01-.006.018a.5.5,0,0,1-.025.076.517.517,0,0,1-.023.062c0,.007,0,.012-.005.015-.01.023-.022.044-.031.068s-.022.047-.033.07a.087.087,0,0,1-.008.014c-.01.017-.021.037-.032.054a.681.681,0,0,1-.047.077.016.016,0,0,0,0,.008c-.014.017-.025.033-.039.049a.611.611,0,0,1-.057.077s-.005.005-.005.01,0,0,0,0l-12.279,14.8a1.68,1.68,0,0,1-2.371.221Z" transform="translate(0.5 0.501)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconSuivant"
}
</script>

<style scoped>

</style>